<header id="page-topbar">
  <div class="navbar-header">
    <div class="d-flex">
      <!-- LOGO -->
      <div class="navbar-brand-box" style="padding: 0">
        <a href="/" class="logo logo-dark">
          <span class="logo-sm">
            <img src="assets/images/logo.svg" alt="" height="22">
          </span>
          <span class="logo-lg">
            <img src="assets/images/logo-dark.png" alt="" height="17">
          </span>
        </a>

        <a href="/" class="logo logo-light">
          <span class="logo-sm">
            <img src="assets/images/cinebooking_logo.png" alt="" width="100%">
          </span>
          <span class="logo-lg">
            <img src="assets/images/cinebooking_logo.png" alt="" width="100%">
          </span>
        </a>
      </div>

      <button type="button" class="btn btn-sm px-3 font-size-16 d-lg-none header-item" (click)="toggleMenubar()">
        <i class="fa fa-fw fa-bars"></i>
      </button>

      <!-- App Search-->
    </div>

    <div class="d-flex">

      <div class="dropdown d-inline-block" ngbDropdown>
        <button type="button" class="btn header-item" id="page-header-user-dropdown" ngbDropdownToggle>
          <img class="rounded-circle header-profile-user" src="assets/images/users/avatar-1.jpg" alt="Header Avatar">
          <span class="d-none d-xl-inline-block ml-1">Henry</span>
          <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
          <!-- item-->
          <a class="dropdown-item" href="javascript: void(0);"><i class="bx bx-user font-size-16 align-middle mr-1"></i>
            {{ 'HEADER.LOGIN.PROFILE' | translate}}</a>
          <a class="dropdown-item" href="javascript: void(0);"><i
              class="bx bx-wallet font-size-16 align-middle mr-1"></i>{{ 'HEADER.LOGIN.MY_WALLET' | translate}}</a>
          <a class="dropdown-item d-block" href="javascript: void(0);"><span
              class="badge badge-success float-right">11</span><i
              class="bx bx-wrench font-size-16 align-middle mr-1"></i>{{ 'HEADER.LOGIN.SETTINGS' | translate}} </a>
          <a class="dropdown-item" href="javascript: void(0);"><i
              class="bx bx-lock-open font-size-16 align-middle mr-1"></i>
            {{ 'HEADER.LOGIN.LOCK_SCREEN' | translate}}</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item text-danger" href="javascript: void(0);" (click)="logout()"><i
              class="bx bx-power-off font-size-16 align-middle mr-1 text-danger"></i>
            {{ 'HEADER.LOGIN.LOGOUT' | translate}}</a>
        </div>
      </div>
    </div>
  </div>
</header>
<div class="topnav">
  <div class="container-fluid">
    <nav class="navbar navbar-light navbar-expand-lg topnav-menu active">
      <div class="collapse navbar-collapse active" id="topnav-menu-content">
        <ul class="navbar-nav">
          <!-- Menu data -->
          <ng-container *ngFor="let item of menuItems">
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle arrow-none" href="javascript: void(0);" id="topnav-components"
                [routerLink]="item.link" role="button">
                <i class="bx {{item.icon}} mr-2"></i>{{ item.label | translate }} <div class="arrow-down"
                  *ngIf="hasItems(item)"></div>
              </a>
              <div class="dropdown-menu row" aria-labelledby="topnav-dashboard" *ngIf="hasItems(item)">
                <ng-template ngFor let-i="index" let-subitem [ngForOf]="item.subItems">
                  <a class="col dropdown-item side-nav-link-ref" *ngIf="!hasItems(subitem)" [routerLink]="subitem.link"
                    routerLinkActive="active">{{subitem.label | translate }}</a>

                  <div class="dropdown" *ngIf="hasItems(subitem)">
                    <a class="dropdown-item" (click)="onMenuClick($event)">{{ subitem.label | translate }}
                      <div class="arrow-down"></div>
                    </a>
                    <div class="dropdown-menu">
                      <ng-template ngFor let-subSubitem [ngForOf]="subitem.subItems">
                        <a class="dropdown-item side-nav-link-ref" [routerLink]="subSubitem.link"
                          routerLinkActive="active">{{ subSubitem.label | translate }}</a>
                      </ng-template>
                    </div>
                  </div>
                </ng-template>
              </div>
            </li>
          </ng-container>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle arrow-none" href="javascript: void(0);" id="topnav-layout" role="button"
              (click)="onMenuClick($event)">
              <i class="bx bx-layout mr-2"></i>{{'MENUITEMS.LAYOUTS.TEXT' | translate}} <div class="arrow-down"></div>
            </a>
            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="topnav-layout">
              <a href="javascript: void(0);" class="dropdown-item"
                (click)="changeLayout('vertical')">{{ 'MENUITEMS.LAYOUTS.LIST.VERTICAL' | translate}}</a>
              <a href="javascript: void(0);" class="dropdown-item"
                (click)="topbarLight()">{{ 'MENUITEMS.LAYOUTS.LIST.LIGHTTOPBAR' | translate}}</a>
              <a href="javascript: void(0);" class="dropdown-item"
                (click)="boxedWidth()">{{ 'MENUITEMS.LAYOUTS.LIST.BOXED' | translate}}</a>
              <a href="javascript: void(0);" class="dropdown-item"
                (click)="coloredHeader()">{{ 'MENUITEMS.LAYOUTS.LIST.COLOREDHEADER' | translate}}</a>
            </div>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</div>
