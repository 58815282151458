import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SingIn } from '../models/singin.models';

@Injectable({ providedIn: 'root' })

export class AuthenticationService {
    private resource = 'cms/';
    onAutentichate = new EventEmitter();

    constructor(
        private http: HttpClient,
        private cookieService: CookieService
    ) { }

    singin(username: string, password: string) {
        return this.http.post(`${environment.api}${this.resource}singin`, { username, password });
    }

    isAuthenticated() {
        const token = this.cookieService.get('token');
        if (!token) {
            return of(false);
        }
        return this.http.post(`${environment.api}${this.resource}isAuthenticated`, {});
    }
}

