import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthenticationService } from '../services/auth.service';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService,
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
        return from(this.authenticationService.isAuthenticated()).pipe(map((auth) => {
            if (auth) {
                return true;
            }
            this.router.navigate(['/account/login'], { queryParams: { returnUrl: state.url } });
            return false;
        }, (error) => {
            this.router.navigate(['/account/login'], { queryParams: { returnUrl: state.url } });
        }));
    }
}
