import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CmsService {
  private resource = 'cms/';

  constructor(private http: HttpClient) { }

  subscriptions(params) {
    return this.http.post(`${environment.api}${this.resource}subscriptions`, params)
  }

  cancelSubscriptions(params) {
    return this.http.post(`${environment.api}${this.resource}cancelSubscriptions`, params)
  }

  invoices(params) {
    return this.http.post(`${environment.api}${this.resource}invoices`, params)
  }

  setInvoiceWaiting(params) {
    return this.http.post(`${environment.api}${this.resource}waitingInvoice`, params)
  }

  setInvoiceWorked(params) {
    return this.http.post(`${environment.api}${this.resource}workedInvoice`, params)
  }

  counters() {
    return this.http.get(`${environment.api}${this.resource}counters`)
  }

  subscriptionCounters() {
    return this.http.get(`${environment.api}${this.resource}subscriptionCounters`)
  }

  cancelSubscriptionCounters() {
    return this.http.get(`${environment.api}${this.resource}cancelSubscriptionCounters`)
  }

 
}
