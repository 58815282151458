import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
    {
        id: 1,
        label: 'Dashboard',
        icon: 'bx bxs-dashboard',
        link: '/dashboard',
    },
    {
        id: 1,
        label: 'Utenti',
        icon: 'bx bx-user',
        link: '/users',
    },
    {
        id: 1,
        label: 'All listings',
        icon: 'bx bx-menu',
        link: '/all-listings',
    },
    {
        id: 1,
        label: 'Matchs',
        icon: 'bx bxs-heart',
        link: '/all-matchs',
    },
    {
        id: 1,
        label: 'Premium',
        icon: 'bx bxs-wallet',
        link: '/subscriptions',
    },
    {
        id: 1,
        label: 'Disdette',
        icon: 'bx bxs-message-alt-x',
        link: '/canceled-subscription',
    },
    {
        id: 1,
        label: 'Fatture',
        icon: 'bx bxs-file',
        link: '/invoices',
    },
    {
        id: 1,
        label: 'Suggerimenti',
        icon: 'bx bxs-report',
        link: '/suggestions',
    },
    {
        id: 1,
        label: 'Contatti',
        icon: 'bx bxs-contact',
        link: '/contacts',
    },
    {
        id: 1,
        label: 'Feedbacks',
        icon: 'bx bxs-comment-dots',
        link: '/feedbacks',
    }
];

